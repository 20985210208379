<template>
  <div class="">
    <div class="configure bg-z">
      <el-tabs v-model="activeName" type="card">
        <el-tab-pane disabled label="班级" name="first"></el-tab-pane>

        <el-tab-pane label="班级列表" name="two">
          <div class="bg-white padding">
            <div>
              <el-button type="primary" size="mini" @click="goNav('/educationCenter/newClass')">新建班级</el-button>
              <!-- <el-button size="mini" @click="editHandle">导入班级</el-button> -->
            </div>
            <div class="work-title-all flex justify-content-between aligin-items-center flex-wrap-wrap margin-top-sm">
              <div class="work-title-content flex aligin-items-center">
                <el-popover width="200" trigger="click">
                  <!-- <el-input v-model="queryList.open_school" placeholder="" size="mini" /> -->
                  <el-checkbox-group v-model="queryList.school_id">
                    <el-checkbox class="padding-xs" :label="item.id" v-for="(item, index) of schoolList" :key="index">{{ item.name }}</el-checkbox>
                  </el-checkbox-group>
                  <div slot="reference">
                    <span>校区</span>
                    <i class="el-icon-caret-bottom" />
                    <i class="border-right" />
                  </div>
                </el-popover>

                <el-popover width="200" trigger="click">
                  <!-- <el-input v-model="classInput" placeholder="" size="mini" /> -->
                  <el-checkbox-group v-model="queryList.curriculum_type">
                    <el-checkbox class="padding-xs" :label="item.id" v-for="(item, index) of courseClassList" :key="index">{{ item.title }}</el-checkbox>
                  </el-checkbox-group>
                  <div slot="reference">
                    <span>课程类别</span>
                    <i class="el-icon-caret-bottom" />
                    <i class="el-icon-link" />
                    <span class="text-grey">课程</span>
                    <i class="el-icon-caret-bottom text-grey" />
                    <span class="border-right" />
                  </div>
                </el-popover>
                <el-popover width="150" trigger="click">
                  <el-checkbox-group v-model="queryList.curriculum_week">
                    <el-checkbox class="padding-xs" :label="item.label" v-for="(item, index) of weekList" :key="index">{{item.label}}</el-checkbox>
                  </el-checkbox-group>
                  <div slot="reference" class="cursor">
                    <span>上课星期</span>
                    <i class="el-icon-caret-bottom" />
                  </div>
                </el-popover>
                <!-- <el-popover width="150" trigger="click" @show="isShow = !isShow" @hide="isShow = !isShow">
                  <el-radio-group v-model="isArrearage">
                    <el-radio :label="3" class="padding-xs">是</el-radio>
                    <el-radio :label="6" class="padding-xs">否</el-radio>
                  </el-radio-group>
                  <div slot="reference">
                    <span>是否欠费</span>
                    <i :class="isShow == true ? 'el-icon-caret-top' : 'el-icon-caret-bottom'" />
                    <span class="border-right" />
                  </div>
                </el-popover> -->

                <!-- <el-popover width="150" trigger="click">
                  <el-checkbox-group v-model="orderTagList">
                    <el-checkbox class="padding-xs" label="未开班" />
                    <el-checkbox class="padding-xs" label="开班中" />
                    <el-checkbox class="padding-xs" label="已结课" />
                  </el-checkbox-group>
                  <div slot="reference">
                    <span>班级状态</span>
                    <i class="el-icon-caret-bottom" />
                    <span class="border-right" />
                  </div>
                </el-popover> -->
                <div>
                  <el-collapse-transition>
                    <div class="flex" v-show="show4">
                      <!-- <el-popover width="180" trigger="click">
                        <el-radio-group v-model="Star"><el-radio class="padding-xs" :label="item.label" v-for="(item, index) of starList" :key="index" /></el-radio-group>
                        <div slot="reference">
                          <span>星标</span>
                          <i class="el-icon-caret-bottom" />
                          <span class="border-right" />
                        </div>
                      </el-popover> -->
                      <el-popover width="150" trigger="click">
                        <el-radio-group v-model="balance">
                          <el-radio class="padding-xs" label="按课时" />
                          <el-radio class="padding-xs" label="按期" />
                          <el-radio class="padding-xs" label="按时间" />
                        </el-radio-group>
                        <div slot="reference">
                          <span>收费模式</span>
                          <i class="el-icon-caret-bottom" />
                          <span class="border-right" />
                        </div>
                      </el-popover>
                      <el-popover width="150" trigger="click">
                        <el-radio-group v-model="balance">
                          <el-radio class="padding-xs" label="开始" />
                          <el-radio class="padding-xs" label="停止" />
                          <el-radio class="padding-xs" label="满班后停止" />
                        </el-radio-group>
                        <div slot="reference">
                          <span>招生状态</span>
                          <i class="el-icon-caret-bottom" />
                          <span class="border-right" />
                        </div>
                      </el-popover>
                      <el-popover width="250" trigger="click">
                        <el-date-picker ref="datePicker" v-model="value1" type="daterange" range-separator="-" start-placeholder="开始日期" end-placeholder="结束日期" />
                        <div slot="reference">
                          <span>开班时间</span>
                          <i class="el-icon-caret-bottom" />
                          <span class="border-right" />
                        </div>
                      </el-popover>
                      <el-popover width="250" trigger="click">
                        <el-date-picker ref="datePicker" v-model="value2" type="daterange" range-separator="-" start-placeholder="开始日期" end-placeholder="结束日期" />
                        <div slot="reference">
                          <span>结班时间</span>
                          <i class="el-icon-caret-bottom" />
                          <span class="border-right" />
                        </div>
                      </el-popover>
                      <el-popover width="150" trigger="click">
                        <el-checkbox-group v-model="orderTagList">
                          <el-checkbox class="padding-xs" label="美术" />
                          <el-checkbox class="padding-xs" label="面试课" />
                          <el-checkbox class="padding-xs" label="英语音标课1对1" />
                        </el-checkbox-group>
                        <div slot="reference">
                          <span>科目</span>
                          <i class="el-icon-caret-bottom" />
                          <span class="border-right" />
                        </div>
                      </el-popover>
                      <el-popover width="150" trigger="click">
                        <el-checkbox-group v-model="orderTagList">
                          <el-checkbox class="padding-xs" label="春季" />
                          <el-checkbox class="padding-xs" label="夏季" />
                          <el-checkbox class="padding-xs" label="秋季" />
                          <el-checkbox class="padding-xs" label="冬季" />
                        </el-checkbox-group>
                        <div slot="reference">
                          <span>学季</span>
                          <i class="el-icon-caret-bottom" />
                          <span class="border-right" />
                        </div>
                      </el-popover>
                    </div>
                  </el-collapse-transition>
                </div>
              </div>

              <div class="work-title-search flex aligin-items-center" style="30%">
                <el-input placeholder="请输入教师、助教、班级、课程名称查询" v-model="queryList.KeyWord" class="input-with-select" size="mini" style="width: 200px;">
                  <!-- <el-select v-model="stuSelect" slot="prepend" placeholder="学员姓名">
                    <el-option label="张龙" value="1" />
                    <el-option label="陈欢" value="2" />
                    <el-option label="周杰" value="3" />
                    <el-option label="朱均" value="3" />
                  </el-select> -->
                  <!-- <el-button slot="append" icon="el-icon-search" /> -->
                </el-input>
                <!-- <span class="text-blue margin-left-sm cursor" @click="show4 = !show4">
                  更多筛选
                  <i class="el-icon-caret-bottom"></i>
                </span> -->
              </div>
            </div>
            <!-- 筛选显示 -->
            <!-- <div class="work-title-status flex margin-top-sm aligin-items-center justify-content-between">
              <div class="flex aligin-items-center">
                <div class="text-gray flex aligin-items-center">
									<div>经办日期：</div>
									<el-date-picker ref="datePicker" v-model="date" type="daterange" range-separator="-"
										start-placeholder="开始日期" end-placeholder="结束日期" size="mini" />
								</div>
                <div class="text-gray flex aligin-items-center  margin-left-lg">
                  <div>订单状态：</div>
                  <div v-for="(btn, btnIndex) in orderSatus" :key="btnIndex">
                    <div :class="{ 'margin-left': btnIndex > 0 }">
                      <el-button size="mini">
                        {{ btn }}
                        <i
                          class="el-icon-close"
                          @click="delOderStatus(btnIndex)"
                        />
                      </el-button>
                    </div>
                  </div>
                </div>
              </div>

              <div class="text-blue">清空筛选</div>
            </div> -->
          </div>

          <!-- 列表 -->
          <div class="work-list margin-top bg-white">
            <div class="padding flex justify-content-between">
              <div class="flex">
                <el-button size="mini" @click="selDel">批量删除</el-button>
                <!-- <el-button size="mini">批量编辑</el-button> -->
                <!-- <el-button size="mini">导出班级</el-button> -->
              </div>
              <div class="flex">
                <!-- <el-checkbox class="padding-xs" label="显示已结课班级" /> -->
                <el-popover width="270" trigger="click">
                  <el-checkbox-group v-model="selList" style="display: flex; flex-wrap: wrap">
                    <el-checkbox class="padding-xs" style="flex: 1" :label="item.label" v-for="(item, index) of customizeList" :key="index" />
                  </el-checkbox-group>
                  <div slot="reference"><el-button type="primary" size="mini">自定义显示列</el-button></div>
                </el-popover>
              </div>
            </div>
            <div class="padding border-tb">
              当前结果：共计
              <label>{{total}}</label>
              个班级 |
              <label>{{StartClassnum}}</label>
              个班级正在开班；共计在读学员
              <label>{{CurrentStudentsnum}}</label>
              名
            </div>
            <as-list ref="reload" :ajaxMethods="{ list: 'getClassList' }" :listQueryProp="queryList" @SelectionChange="selectAllChange">
              <el-table-column align="center" type="selection"></el-table-column>
              <el-table-column prop="class_name" label="班级名称" width="200" />
              <el-table-column align="center" sortable prop="class_count" label="人数" width="75" />
              <el-table-column v-if="selList.indexOf('满班率') > -1" align="center" prop="class_num" label="满班率" width="75" />
              <el-table-column align="center" prop="Tlistname" label="教师" />
              <el-table-column v-if="selList.indexOf('助教') > -1" align="center" prop="Alistname" label="助教" />
              <el-table-column v-if="selList.indexOf('上课教室') > -1" align="center" prop="room_name" label="上课教室" />
              <el-table-column v-if="selList.indexOf('课程类别') > -1" align="center" prop="curriculum.curriculum_name" label="课程类别" />
              <el-table-column v-if="selList.indexOf('收费标准') > -1" prop="" label="收费标准" width="150">
                <template slot-scope="scope">
                  <div v-if="scope.row.curriculum">
                    <div v-for="item in scope.row.curriculum.listm" :key="scope.row.id">
                      <span>{{ item.num }}课时 = {{ item.money }}元</span>
                    </div>
                  </div>
                </template>
              </el-table-column>
              <el-table-column v-if="selList.indexOf('结班日期') > -1" align="center" label="结班日期">
                <template slot-scope="scope">
                  <span>{{ scope.row.endtime | dateTimeFilter('yyyy-MM-dd') }}</span>
                </template>
              </el-table-column>
              <el-table-column align="center" prop="to_class_time" label="上课时间">
                <template slot-scope="scope">
                  <span>{{ scope.row.starttime | dateTimeFilter('yyyy-MM-dd') }}</span>
                </template>
              </el-table-column>
              <el-table-column v-if="selList.indexOf('创建日期') > -1" align="center" label="创建日期">
                <template slot-scope="scope">
                  <span>{{ scope.row.addtime | dateTimeFilter('yyyy-MM-dd') }}</span>
                </template>
              </el-table-column>
              <el-table-column v-if="selList.indexOf('创建人') > -1" align="center" prop="room_name" label="创建人" />
              <el-table-column v-if="selList.indexOf('所属课程') > -1" prop="curriculum.curriculum_name" label="所属课程" width="200" />
              <el-table-column align="center" prop="school_name" label="开班校区" width="100" />
              <el-table-column v-if="selList.indexOf('开班日期') > -1" align="center" prop="starttime" sortable label="开班日期" width="105">
                <template slot-scope="scope">
                  <span>{{ scope.row.starttime | dateTimeFilter('yyyy-MM-dd') }}</span>
                </template>
              </el-table-column>
              <el-table-column v-if="selList.indexOf('招生状态') > -1" align="center" prop="recruit_status" label="招生状态">
                <el-tag type="success" size="small" effect="dark" style="border-radius: 20px">开放招生</el-tag>
              </el-table-column>
              <el-table-column v-if="selList.indexOf('备注') > -1" align="center" prop="remarks" label="备注" />
              <el-table-column align="center" label="操作" fixed="right">
                <template slot-scope="scope">
                  <el-dropdown trigger="click" @command="item => handleCommand(item, scope.row)">
                    <label>
                      操作
                      <i class="el-icon-arrow-down el-icon-caret-bottom"></i>
                    </label>
                    <el-dropdown-menu slot="dropdown">
                      <el-dropdown-item command="Schedule">排课记上课</el-dropdown-item>
                      <el-dropdown-item command="unSchedule">未排课记上课</el-dropdown-item>
                      <!-- <el-dropdown-item>分配学员</el-dropdown-item> -->
                      <el-dropdown-item command="arrange">排课</el-dropdown-item>
                      <el-dropdown-item command="end">结班</el-dropdown-item>
                      <el-dropdown-item command="edit">编辑</el-dropdown-item>
                      <el-dropdown-item command="del">删除班级</el-dropdown-item>
                    </el-dropdown-menu>
                  </el-dropdown>
                </template>
              </el-table-column>
            </as-list>
          </div>
        </el-tab-pane>
        <el-tab-pane lazy label="分班操作日志" name="three"><ShiftClassLog></ShiftClassLog></el-tab-pane>
      </el-tabs>
      <el-dialog title="删除班级" :visible.sync="delVisible" width="30%">
        <span>是否确认删除班级？</span>
        <span slot="footer" class="dialog-footer">
          <el-button @click="delVisible = false">取 消</el-button>
          <el-button type="primary" @click="sureDelclass">确 定</el-button>
        </span>
      </el-dialog>
      <el-dialog title="结班" :visible.sync="endVisible" width="30%">
        <div class="margin-top-sm">确定要结班吗？</div>
        <div class="margin-top-sm"><el-checkbox v-model="endChecked">同时将学员的课程结课</el-checkbox></div>
        <div class="margin-top-sm">
          <i class="el-icon-info text-blue el-icon--left"></i>
          <span class="text-grey">课程结课后，学员的剩余学费将自动转成学校非课消收入</span>
        </div>
        <span slot="footer" class="dialog-footer">
          <el-button @click="endVisible = false">取 消</el-button>
          <el-button type="primary" @click="sureEndclass">确 定</el-button>
        </span>
      </el-dialog>

      <classImport :Visible="editVisible" :titles="name" @closepop="closeEditPop"></classImport>
    </div>
  </div>
</template>
<script>
import AsList from '@/components/as-list';
import classImport from '../components/classImport.vue';
import ShiftClassLog from './child/shiftClassLog.vue';
import { weekList } from '@/config/index';
	import { formatTime } from '@/utils/util.js';
export default {
  components: {
    AsList,
    classImport,
    ShiftClassLog
  },
  data() {
    return {
      weekList,
      name: '班级导入',
      isShow: false,
      show4: false,
      date: '',
      value1: '', //开班时间
      value2: '', //结班时间
      activeName: 'two',
      stuInput: '', // 学生姓名查询
      stuSelect: '',
      isArrearage: 3,
      classInput: '',
      Star: '',
      balance: '',
      orderTagList: [], // 订单标签
      orderSatus: ['待支付', '已完成'], // 订单状态
      customizeList: [
        /* {
          label: '星标',
          value: '1'
        }, */
        {
          label: '满班率',
          value: '2'
        },
        /* {
          label: '升期率',
          value: '3'
        }, */
        {
          label: '助教',
          value: '4'
        },
        {
          label: '上课教室',
          value: '5'
        },
        {
          label: '所属课程',
          value: '6'
        },
        {
          label: '课程类别',
          value: '7'
        },
        {
          label: '收费标准',
          value: '8'
        },
        {
          label: '开班日期',
          value: '9'
        },
        {
          label: '结班日期',
          value: '10'
        },
        {
          label: '上课时间',
          value: '11'
        },
        {
          label: '招生状态',
          value: '12'
        },
        {
          label: '创建时间',
          value: '13'
        },
        {
          label: '创建人',
          value: '14'
        },
        {
          label: '备注',
          value: '15'
        }
      ],
      selList: [],
      datalist: [],
      queryList: {
        KeyWord: '',
        school_id: [],
        curriculum_id: [],
        student_id: [],
        curriculum_type: [],
        curriculum_week: [],
        curr_mode: -1
      },
      datacount: 0,
      delVisible: false,
      delClassId: 0,
      endVisible: false,
      endChecked: false,
      // 筛选
      listQuery1: {
        HasRole: false,
        module: '',
        PageIndex: 1,
        PageSize: 10
      },
      schoolList: [], // 经办校区
      courseClassList: [],
      SubjectTypeList: [],
      // 批量操作
      selIDList: [],
	  StartClassnum:0,//开班数
	  total:0,//总数
	  CurrentStudentsnum:0,//在读学生数
    };
  },
  created() {
    this.getSchoolList();
    this.getCourseClass();
    this.getSubjectTypeList();
	this.getClass()
  },
  methods: {
	  //获取所有班级
	  getClass(){
		this.API.getClassList(this.queryList).then(res=>{
			let list=res.data.rows
			this.total=res.data.total
			let time=formatTime(new Date(),'yyyy-MM-dd')
			let map=list.filter(t=>formatTime(t.starttime,'yyyy-MM-dd')<time && time<formatTime(t.endtime,'yyyy-MM-dd'))
			this.StartClassnum=map.length
			list.forEach(item=>{
				this.CurrentStudentsnum=this.CurrentStudentsnum+item.class_count
			})
		})
	  },
    // 获取校区
    getSchoolList() {
      let obj = {
        type: 1,
        PageIndex: 1,
        PageSize: 10
      };
      this.API.OrganizationList(obj).then(res => {
        if (res.success) {
          this.schoolList = res.data.rows;
        }
      });
    },
    getCourseClass() {
      this.listQuery1.module = 'course_class';
      this.$Dictionary.GetDictionarys(this.listQuery1).then(res => {
        this.courseClassList = res.data.rows;
        this.$forceUpdate();
      });
    },
    getSubjectTypeList() {
      this.listQuery1.module = 'SubjectType';
      this.$Dictionary.GetDictionarys(this.listQuery1).then(res => {
        this.SubjectTypeList = res.data.rows;
        this.$forceUpdate();
      });
    },
    // 批量选择
    selectAllChange(e) {
      this.selIDList = []
      e.map(t => {
        this.selIDList.push(t.id)
      })
      // console.log(this.selIDList);
    },
    // 批量删除
    selDel() {
      this.delClassId = 0
      if(this.selIDList.length > 0) {
        this.delVisible = true;
        this.delClassId = this.selIDList.join(',')
        console.log(this.delClassId);
      }else {
        this.$message.error('请选择班级')
      }
    },
    // 列表操作
    handleCommand(type, val) {
	
      if (type == 'del') {
        this.delVisible = true;
        this.delClassId = val.id;
      } else if (type == 'edit') {
        this.$router.push({ name: 'editClass', params: { id: val.id } });
      } else if (type == 'end') {
        this.delClassId = val.id;
        this.endVisible = true;
      } else if (type == 'arrange') {
        this.$router.push({ name: 'newSchedule', params: { id: val.id, className: val.class_name } });
      }else if(type=='Schedule'){
		
		  if(val.arrange_id==0){
			  this.$message.error("今日无上课安排，如果您需要补记以往的上课记录，请从课表或排课记录中进入");
		  }else{
			  this.$router.push({
			    path: "/educationCenter/addRemembertoClass",
			    query: { classid: val.id },
			  });
		  }
	  }else if (type=="unSchedule"){
		  console.log(val)
		  this.$router.push({
		    path: "/educationCenter/addRemembertoClass",
		    query: { classid: val.id,},
		  });
	  }
    },
    // 确认删除班级
    sureDelclass() {
      this.API.updateClassStatus(this.delClassId, -99, false).then(res => {
        if (res.code == 200) {
          this.delVisible = false;
          this.$refs.reload.reload();
          this.$message.success('删除成功');
        }else {
          this.$message.error(res.message);
        }
      });
    },
    // 确认结课
    sureEndclass() {
      this.API.updateClassStatus(this.delClassId, 2, this.endChecked).then(res => {
        if (res.code == 200) {
          this.endVisible = false;
          this.$refs.reload.reload();
          this.$message.success('结课成功');
        }
      });
    },
    
    openDate() {
      this.$refs.datePicker.pickerVisible = true;
    },
    delOderStatus(index) {
      this.orderSatus.splice(index, index + 1);
    },
    goNav(url) {
      this.$router.push(url);
    }
  }
};
</script>
<style scoped>
.icon {
  width: 50px;
  height: 50px;
  vertical-align: -0.15em;
  fill: currentColor;
  overflow: hidden;
}

.el-input__inner {
  width: 220px !important;
}

>>> .el-select .el-input {
  width: 110px !important;
}

.el-button--mini {
  border-radius: 4px !important;
  padding: 6px 15px !important;
}
</style>
