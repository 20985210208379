<template>
  <div>
    <div class="work-title bg-white margin-top padding">
      <div class="work-title-all flex justify-content-between aligin-items-center flex-wrap-wrap">
        <div class="work-title-content flex aligin-items-center">
          <el-popover width="400" trigger="click">
            <el-date-picker size="mini" ref="datePicker" v-model="value1" type="daterange" range-separator="-" start-placeholder="开始日期" end-placeholder="结束日期" />
            <div slot="reference">
              <span>分班日期</span>
              <i class="el-icon-caret-bottom" />
              <span class="border-right" />
            </div>
          </el-popover>
          <el-popover width="150" trigger="click">
            <!-- <el-input v-model="classInput" placeholder="" size="mini" /> -->
            <el-checkbox-group v-model="queryList.school_id">
              <el-checkbox class="padding-xs" :label="item.id" v-for="(item, index) of schoolList" :key="index">{{ item.name }}</el-checkbox>
            </el-checkbox-group>
            <div slot="reference">
              <span>报读校区</span>
              <i class="el-icon-caret-bottom" />
              <span class="border-right" />
            </div>
          </el-popover>

          <el-popover width="150" trigger="click">
            <el-radio-group v-model="balance">
              <el-radio class="padding-xs" label="办理分班" />
              <el-radio class="padding-xs" label="同课程转出" />
              <el-radio class="padding-xs" label="同课程转入" />
              <el-radio class="padding-xs" label="编辑档案状态" />
            </el-radio-group>
            <div slot="reference">
              <span>类型</span>
              <i class="el-icon-caret-bottom" />
              <span class="border-right" />
            </div>
          </el-popover>
        </div>

        <div class="work-title-search">
          <el-input placeholder="请输入姓名,支持拼音缩写" v-model="stuInput" class="input-with-select" size="mini" style="width:100%;">
            <el-select v-model="stuSelect" slot="prepend" placeholder="学员姓名">
              <el-option label="张龙" value="1" />
              <el-option label="陈欢" value="2" />
              <el-option label="周杰" value="3" />
              <el-option label="朱均" value="3" />
            </el-select>
            <el-button slot="append" icon="el-icon-search" />
          </el-input>
        </div>
      </div>

      <div class="work-title-status flex margin-top-sm aligin-items-center justify-content-between">
        <div class="flex aligin-items-center">
          <div class="text-gray flex aligin-items-center">
            <div>分期时间：</div>
            <el-date-picker ref="datePicker" v-model="date" type="daterange" range-separator="-" start-placeholder="开始日期" end-placeholder="结束日期" size="mini" />
          </div>
          <!-- <div class="text-gray flex aligin-items-center  margin-left-lg">
    				<div>订单状态：</div>
    				<div v-for="(btn, btnIndex) in orderSatus" :key="btnIndex">
    					<div :class="{ 'margin-left': btnIndex > 0 }">
    						<el-button size="mini">
    							{{ btn }}
    							<i class="el-icon-close" @click="delOderStatus(btnIndex)" />
    						</el-button>
    					</div>
    				</div>
    			</div> -->
        </div>

        <div class="text-blue">清空筛选</div>
      </div>
    </div>

    <!-- 列表 -->
    <div class="work-list margin-top bg-white">
      <div class="padding border-tb">当前结果：共计0条记录</div>

      <as-list :ajaxMethods="{ list: 'UserLog' }" :listQueryProp="queryList">
        <el-table-column align="center" label="时间">
          <template slot-scope="scope">
            <span>{{ scope.row.LogTime | dateTimeFilter('yyyy-MM-dd') }}</span>
          </template>
        </el-table-column>
        <el-table-column align="center" prop="user_name" label="操作人" />
        <el-table-column align="center" prop="student_name" label="学员" />
        <el-table-column align="center" prop="school_name" label="校区" />
        <el-table-column align="center" prop="text5" label="班级" />
        <el-table-column align="center" prop="Title" label="操作类型" />
        <el-table-column prop="Description" label="内容" width="300" />
        <el-table-column align="center" prop="text6" label="开始日期" />
      </as-list>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      datalist: [],
      value1: '',
      classInput: '',
      balance: '',
      stuInput: '',
      stuSelect: '',
      date: '',
      queryList: {
        skip_current_school: false,
        type: [22], //22
        school_id: []
      },
      // 筛选
      listQuery1: {
        HasRole: false,
        module: '',
        PageIndex: 1,
        PageSize: 10
      },
      schoolList: [], // 经办校区
    };
  },
  created() {
    this.getSchoolList();
  },
  methods: {
    // 获取校区
    getSchoolList() {
      let obj = {
        type: 1,
        PageIndex: 1,
        PageSize: 10
      };
      this.API.OrganizationList(obj).then(res => {
        if (res.success) {
          this.schoolList = res.data.rows;
        }
      });
    },
  }
};
</script>

<style scoped></style>
